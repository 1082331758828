@import "_includes/variables";
@import "_includes/bootstrap/variables.scss";

#header-404 {
    color: $color-white;
    padding: 54px 0 110px;
    display: flex;
    justify-content: center;
    background: $color-cbc-extended-midnight-sea-blue;
}

#title-404 {
    font-size: 80px;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
    letter-spacing: 10.4px;
    color: $color-cbc-extended-kid-marker-blue;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;
}

#error-text-404 {
    color: $color-white;
    font-size: 14px;
    font-weight: $font-weight-normal;
    line-height: 1.71;
    letter-spacing: 1.4px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: initial;
}

#subtitle-404 {
    color: $color-white;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 1.19;
    letter-spacing: 1.6px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
}

#subline-404 {
    width: 100%;
    height: 1px;
    display: block;
    margin-top: 35px;
    margin-bottom: 30px;
    position: relative;
    background-color: $color-cbc-extended-kid-marker-blue;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
    }

    &:before {
        width: 20px;
        height: 17px;
        background: url(../img/error/icon-construct.svg) 0 0/cover no-repeat;
        z-index: 2;
    }

    &:after {
        width: 60px;
        height: 57px;
        background-color: $color-cbc-extended-midnight-sea-blue;
    }
}

#error-subtext-404 {
    color: $color-white;
    font-size: 12px;
    font-weight: $font-weight-normal;
    line-height: 2;
    letter-spacing: 1.2px;
    text-align: center;
    margin-bottom: 20px;
}

.links {
    ul {
        list-style: none;
    }

    li {
        &:before {
            width: 3px;
            height: 3px;
            content: '';
            display: inline-block;
            margin-right: 9px;
            border-radius: 50%;
            vertical-align: middle;
            background-color: $color-cbc-extended-kid-marker-blue;
        }
    }

    a {
        font-size: 11px;
        font-weight: $font-weight-bolder;
        line-height: 1.18;
        letter-spacing: 1.1px;
        color: $color-white;
        text-transform: uppercase;

        &:hover {
            color: $color-cbc-extended-energizing-blue;
        }
    }
}

#contact-us-404 {
    padding: 30px;
    margin-top: -50px;
    background-color: $color-cbc-extended-shale-blue;

    .contact-form-wrapper {
        form {
            padding: 0;
        }
    }
}

#contact-us-title-404 {
    font-size: 20px;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
    letter-spacing: 2px;
    color: $color-cbc-brandguidelines-primary;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    #title-404 {
        font-size: 104px;
    }
}
